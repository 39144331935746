import React, { createContext, useState, useContext } from 'react';
import {nanoid} from 'nanoid'

const ApplicationContext = createContext();

export function useApplication() {
  return useContext(ApplicationContext);
}

export const ApplicationProvider = ({ children }) => {
  const [application, setApplication] = useState({ 
    app_args:{
      planYear: '2025'
      , source: ''
      , emergencyFund: 200000
      , pcpCostTolerance: -1
      , qualifyingEvent: "none"
      , dependents: []
      , primary: {isExpanded: true, user_id: nanoid()}
    }
    , claims:[] 
  });

  return (
    <ApplicationContext.Provider value={{ application, setApplication }}>
      {children}
    </ApplicationContext.Provider>
  );
};
